/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage as Translation } from 'react-intl';
import { IconButton } from 'panamera-react-ui';

import css from './styles.scss';

const ValuationFlowHeader = ({
    showBackBtn,
    showCloseBtn,
    title,
    currentStep,
    totalSteps,
    onBackBtnClick,
    onClose,
    testId = 'valuation-flow-header'
}) => {
    const progressCls = classnames(css.progress, {
        [css.noBorder]: !showCloseBtn
    });

    return (
        <div className={ css.wrapper } data-aut-id={ testId }>
            <div className={ css.headerLeft }>
                { showBackBtn && <IconButton
                    icon="icon-Back"
                    className={ css.back }
                    data-aut-id="backBtn"
                    onClick={ onBackBtnClick } />
                }
                <h2 className={ css.title } data-aut-id="title">{ title }</h2>
            </div>
            <div className={ css.headerRight }>
                { !!totalSteps && <div className={ progressCls } data-aut-id="progress">
                    <span className={ css.currentStep }><Translation id="steps" />{` ${currentStep} `}</span>
                    <span className={ css.totalSteps }><Translation id="of" />{` ${totalSteps}`}</span>
                </div> }
                { showCloseBtn && <IconButton icon="icon-Cross"
                    data-aut-id="closeBtn"
                    name="Close"
                    className={ css.close }
                    onClick={ onClose } /> }
            </div>
        </div>
    );
};

ValuationFlowHeader.propTypes = {
    showBackBtn: PropTypes.bool,
    showCloseBtn: PropTypes.bool,
    title: PropTypes.string,
    currentStep: PropTypes.number,
    totalSteps: PropTypes.number,
    onBackBtnClick: PropTypes.func,
    onClose: PropTypes.func,
    testId: PropTypes.string
};

export default ValuationFlowHeader;
