/* eslint-disable no-console */
/* eslint-disable react/no-multi-comp */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import classnames from 'classnames';

import Button from 'Components/Button/Button';
import ValuationFlowHeader from '../ValuationFlowHeader/ValuationFlowHeader';
import FieldsNavigationBar from '../FieldsNavigationBar/FieldsNavigationBar.mobile';
import ValuationFieldsValue from '../ValuationFieldsValue/ValuationFieldsValue';
import ValuationFlowWidgetV2 from '../ValuationFlowWidgetV2/ValuationFlowWidgetV2';

import css from './ValuationFlowView.mobile.scss';

export const GetPriceCta = ({
    onClick,
    footNote,
    isCtaDisabled,
    text
}) => {
    return (
        <div className={ css.ctaWrapper }>
            { footNote && <span className={ css.ctaDescription }>{ footNote }</span> }
            <Button
                type="primaryBtn"
                className={ css.priceButton }
                onClick={ onClick }
                data-aut-id="landing_page_get_price_button"
                disabled={ isCtaDisabled }
            >
                <Translation id={ text } />
            </Button>
        </div>
    );
};

const ValuationFlowView = props => {
    const {
        valuationProps: {
            stepId,
            showBackBtn,
            showCloseBtn,
            title,
            currentStep,
            totalSteps,
            navItems,
            selectedFormValues,
            stepDataValues,
            isFetchingData,
            isError,
            isSearchRequired,
            selectedTabIndex,
            goToStep,
            goToNextStep,
            onBackBtnClick,
            onClose,
            testId = 'valuation-flow-header',
            getSelectFromValue,
            reloadPage,
            registrationNumber,
            numberPlatePreApiCallback,
            numberPlatepostApiCallback,
            setRegistrationNumber,
            isFetchingRcData,
            trackWrapper,
            removeSelectedDataById,
            onGetPriceClick,
            tabs,
            defaultTab,
            metadata = {},
            displayFields,
            onGetPriceCtaClick
        },
        intl,
        marketConfig,
        router,
        localStorage
    } = props;

    const pathName = router.location.pathname;
    const hasAllUserdataValues = useMemo(() => {
        let lastEmptyField;

        for (let i = 0; i < navItems.length; i++) {
            if (!selectedFormValues[navItems[i].id]) {
                lastEmptyField = i;
                break;
            }
        }

        return lastEmptyField == null;
    }, [navItems, selectedFormValues]);

    const renderValuationContent = () => {
        return (<div className={ css.wrapper }>
            <div className={ classnames(css.mainContentWrapper, css.withCTA) }>
                <FieldsNavigationBar { ...{
                    ...{
                        navItems,
                        selectedTabIndex,
                        selectedFormValues,
                        onNavItemClick: goToStep,
                        getContactPageCtaStatus: !!hasAllUserdataValues,
                        onGetPriceClick
                    }
                } } />
                <ValuationFieldsValue { ...{
                    ...{
                        isFetchingData,
                        isError,
                        stepDataValues,
                        isSearchRequired,
                        onFieldValueClick: goToNextStep,
                        selectedFormValues,
                        selectedTabIndex,
                        stepTitle: title,
                        trackWrapper,
                        getSelectFromValue,
                        reloadPage,
                        removeSelectedDataById,
                        intl,
                        marketConfig,
                        displayFields,
                        getContactPageCtaStatus: !!hasAllUserdataValues,
                        onGetPriceClick
                    }
                } } />
            </div>
        </div>);
    };

    const isLandingPage = pathName === marketConfig.get('landingPageRoutes', 'sell-ppc') || pathName === marketConfig.get('landingPageRoutes', 'sell-organic') || pathName === '/' || pathName === '/olxmobbi';

    if (isLandingPage) {
        return (
            <ValuationFlowWidgetV2 { ...{
                ...{
                    tabs,
                    defaultTab,
                    stepId,
                    onFieldValueClick: goToNextStep,
                    selectedFormValues,
                    popularList: stepDataValues?.popular_list || [],
                    title: navItems[0]?.labelDesc,
                    registrationNumber,
                    numberPlatePreApiCallback,
                    numberPlatepostApiCallback,
                    setRegistrationNumber,
                    getSelectFromValue,
                    intl,
                    marketConfig,
                    trackWrapper,
                    isFetchingRcData,
                    localStorage,
                    metadata,
                    displayFields,
                    onGetPriceCtaClick
                }
            } } />
        );
    }

    return (<>
        <ValuationFlowHeader { ...{
            ...{
                showBackBtn,
                showCloseBtn,
                title,
                currentStep,
                totalSteps,
                onClose,
                onBackBtnClick,
                testId
            }
        } } />
        { renderValuationContent() }
    </>);
};

ValuationFlowView.propTypes = {
    valuationProps: PropTypes.object.isRequired,
    intl: PropTypes.object,
    marketConfig: PropTypes.object,
    localStorage: PropTypes.object,
    router: PropTypes.object
};

GetPriceCta.propTypes = {
    onClick: PropTypes.func,
    footNote: PropTypes.string,
    isCtaDisabled: PropTypes.bool,
    text: PropTypes.string
};

export default ValuationFlowView;
