/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import css from './FieldsNavigationBar.mobile.scss';
import { FIELD_CONSTANTS } from '../constants';

const NavListItem = ({
    itemClass,
    label,
    selectedValue,
    onClick
}) => {
    return (
        <li onClick={ onClick } className={ itemClass } data-aut-id="valuation-field-item">
            <div className={ css.title }>{label}</div>
            { selectedValue && <div className={ css.selectedValue }>{selectedValue}</div> }
        </li>
    );
};

const FieldsNavigationBar = ({
    navItems,
    selectedTabIndex,
    selectedFormValues,
    onNavItemClick
}) => {
    return (
        <ul
            className={ css.fieldsNavBarWrapper }
            data-aut-id="valuation-fields">
            {
                navItems.map(({
                    id,
                    key,
                    label
                }, idx) => {
                    const selectedValue = selectedFormValues[`${id}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
                    const isSelected = idx === selectedTabIndex;

                    const itemClass = classnames(css.item, {
                        [css.selected]: isSelected,
                        [css.withValue]: selectedValue != undefined
                    });

                    const clickHandler = () => {
                        if (selectedValue && !isSelected) {
                            onNavItemClick(idx);
                        }
                        return;
                    };

                    return (
                        <NavListItem
                            key={ key }
                            label={ label }
                            itemClass={ itemClass }
                            onClick={ clickHandler }
                            selectedValue={ selectedValue }
                        />)
                    ;
                })
            }
        </ul>
    );
};

FieldsNavigationBar.propTypes = {
    navItems: PropTypes.array.isRequired,
    selectedFormValues: PropTypes.object,
    selectedTabIndex: PropTypes.number,
    onNavItemClick: PropTypes.func
};

NavListItem.propTypes = {
    label: PropTypes.string,
    selectedValue: PropTypes.string,
    itemClass: PropTypes.string,
    onClick: PropTypes.func
};

export default FieldsNavigationBar;
